<template>
  <div>
    <base-toolbar>
      <template v-slot:icon>
        mdi-package-variant
      </template>
      <template v-slot:filters>
        <v-col cols="7" sm="4" md="2" lg="2" class="pl-0 pl-sm-3">
          <v-text-field
            v-model="filterKeyword"
            hide-details
            label="Search"
            placeholder="Search"
            solo
            flat
            background-color="grey"
            dense
            single-line
            append-icon="mdi-magnify"
            class="shrink"
            @input="$emit('update:filter-keyword', $event)"
          />
        </v-col>
        <div class="text-center">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-1"
                elevation="0"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-badge
                  :value="showBadge()"
                  color="primary"
                  overlap
                  dot
                >
                  <v-icon>mdi-filter-outline</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Filter</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider />

              <v-list>
                <v-list-item>
                  <v-select
                    label="Asset type"
                    :value="filterType"
                    :items="assetTypes"
                    solo
                    flat
                    background-color="grey"
                    style="max-width:237px"
                    dense
                    clearable
                    single-line
                    hide-details
                    class="shrink"
                    @input="$emit('update:filter-type', $event)"
                  />
                </v-list-item>

                <v-list-item v-if="isSuperUser">
                  <async-search-select
                    v-model="client"
                    label="Client"
                    :items="clientList"
                    item-text="name"
                    :items-update="fetchClientRefs"
                  />
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
        <v-btn
          v-if="showClearFilterButton"
          color="grey"
          class="mx-2"
          fab
          small
          :elevation="0"
          @click="clearFilter"
        >
          <v-icon color="error">mdi-close-circle-outline</v-icon>
        </v-btn>
        <span v-if="isClientAdmin" class="ml-auto">
          <v-btn
            color="primary"
            class="mr-0"
            depressed
            @click.native="showCreateAsset = true"
          >
            <v-icon class="mr-sm-2">mdi-package-variant-plus</v-icon>
            <span class="d-none d-sm-block">New Asset</span>
          </v-btn>
        </span>
      </template>
    </base-toolbar>
    <create-asset-modal v-model="showCreateAsset" />
  </div>
</template>

<script>
import BaseToolbar from '@/components/toolbar/BaseToolbar'
import CreateAssetModal from '@/components/dialog/assets/CreateAssetModal.vue'
import { assetTypes } from '@/data/common'
import AsyncSearchSelect from '@/components/base/AsyncSearchSelect.vue'
import ClientService from '@/services/client.service'
import UserService from '@/services/user.service'

export default {
  components: {
    AsyncSearchSelect,
    BaseToolbar,
    CreateAssetModal
  },
  props: {
    filterKeyword: {},
    filterType: {
      type: String,
      default: null
    },
    filterClient: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {
      clientList: [],
      assetTypes,
      isClientAdmin: false,
      isSuperUser: false,
      menu: false,
      showCreateAsset: false
    }
  },
  computed: {
    showClearFilterButton() {
      return !!(this.filterKeyword || this.filterType || this.filterClient)
    },
    client: {
      get() {
        return this.filterClient
      },
      set(value) {
        this.$emit('update:filter-client', value)
      }
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isClientAdmin = profile.role === 'Client Admin' || profile.role === 'Superuser'

      this.isSuperUser = profile.role === 'Superuser'
    }
  },
  methods: {
    async fetchClientRefs(search) {
      ClientService.getClientRefs(1, 10, search).then((resp) => {
        this.clientList = resp.results
      })
    },
    showBadge() {
      if (this.filterStatus || this.filterType || this.filterClient || this.filterAsset) {
        return true
      } else {
        return ''
      }
    },
    clearFilter() {
      this.$emit('update:filter-keyword', '')
      this.$emit('update:filter-type', null)
      this.$emit('update:filter-client', null)
    }
  }
}
</script>
