<template>
  <v-row justify="center">
    <v-dialog
      :value="value"
      width="800px"
      transition="dialog-bottom-transition"
      @click:outside="closeModal"
      @input="$emit('input', $event)"
    >
      <v-card :elevation="0">
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            <v-icon large color="white" class="text-36 mr-4">
              mdi-package-variant-plus
            </v-icon>
            New Asset
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="closeModal"
              @keyup="closeModal"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-stepper v-model="stepperStep" alt-labels>
          <v-stepper-header>
            <v-stepper-step
              color="success"
              :complete="stepperStep > 1"
              step="1"
              :elevation="0"
              class="mb-0 pb-0"
            >
              <div class="text-center">
                <h5 class="my-0">Definition</h5>
                <small>Define your asset</small>
              </div>
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              color="success"
              :complete="stepperStep > 2"
              step="2"
            >
              <div class="text-center">
                <h5 class="my-0">Targets</h5>
                <small>Provide targets and description</small>
              </div>
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              step="3"
              color="success"
            >
              <div class="text-center">
                <h5 class="my-0">Review</h5>
                <small>Confirm targets and details</small>
              </div>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1" class="pt-0 mt-0">
              <div class="px-6 ul-icon-box-animate-onhover">
                <div class="text-center mx-auto mb-3">
                  <v-img
                    height="80"
                    width="80"
                    class="mx-auto"
                    src="@/assets/images/svg/data-security.svg"
                  />
                  <h4 class="font-weight-bold mb-0 mt-2">Create new Asset</h4>
                  <p class="text--lighten-1">Targeted pentesting assets</p>
                </div>
                <v-form
                  ref="form1"
                  v-model="valid1"
                  lazy-validation
                >
                  <v-row v-if="isSuperUser" dense class="mt-0 pt-0 justify-center">
                    <v-col cols="12" class="pt-0">
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        CLIENT <span class="h6 primary--text text--lighten-2">*</span>
                      </div>
                      <async-search-select
                        v-model="client"
                        placeholder="Select a client"
                        :items="itemsClient"
                        item-text="name"
                        :items-update="fetchClientRefs"
                        :rules="[v => (!isSuperUser || !!v) || 'This field is required']"
                        base-style=""
                        :style-extra="{ dense: true, flat: true, outlined: true, 'hide-details': false}"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        NAME <span class="h6 primary--text text--lighten-2">*</span>
                      </div>
                      <v-text-field
                        v-model="assetName"
                        label="Give it a name"
                        :rules="[v => !!v || 'This field is required']"
                        value=""
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                        TYPE <span class="h6 primary--text text--lighten-2">*</span>
                      </div>
                      <v-select
                        v-model="assetType"
                        label="Define asset type"
                        :rules="[v => !!v || 'This field is required']"
                        :items="assetTypes"
                        outlined
                        single-line
                        dense
                        hide-details
                        class="shrink"
                      />
                    </v-col>
                  </v-row>
                  <!-- <v-row dense class="mt-0 pt-0 justify-center">
                    <v-col
                      cols="12"
                      md="8"
                      lg="6"
                      class="mt-4 pt-0"
                    >
                      <div class="primary--text font-weight-bold mb-1">
                        TECHNOLOGY <small class="font-italic font-weight-thin">(Optional)</small>
                      </div>
                      <v-combobox
                        v-model="assetSelectedTechnologies"
                        :items="assetTechnos"
                        :rules="[v => !!v || 'This field is required']"
                        label="Select relevant technologies"
                        chips
                        multiple
                        outlined
                        clearable
                      />
                    </v-col>
                  </v-row> -->
                </v-form>
              </div>

              <div class="text-right mt-10 mr-4">
                <v-btn color="primary" @click="nextStep(2)">
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="pt-0 mt-0">
              <v-form
                ref="form2"
                v-model="valid2"
                lazy-validation
                class="mb-12 mx-6 mt-0 pt-0"
                :elevation="0"
              >
                <v-row dense class="mt-0 pt-0 justify-center">
                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                      DESCRIPTION <small class="font-italic font-weight-thin">(Optional)</small>
                    </div>
                    <v-textarea
                      v-model="assetDescription"
                      label="Enter a brief description"
                      value=""
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row dense class="mt-0 pt-0 justify-center">
                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    <div class="primary--text text--lighten-2 font-weight-bold mb-1">
                      TARGETS <span class="h6 primary--text text--lighten-2">*</span>
                    </div>
                    <v-textarea
                      v-model="assetTargets"
                      label="Provide targets, one per row"
                      :rules="[v => !!v || 'This field is required']"
                      value=""
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </v-form>

              <div class="text-right mt-n10 mr-4">
                <v-btn color="primary" @click="stepperStep = 1" outlined class="mr-3">
                  Back
                </v-btn>

                <v-btn color="primary" @click="nextStepF2(3)">
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3" class="pt-0 mt-0">
              <v-card class="mb-3 mt-0 px-6 removeBG" :elevation="0">
                <v-row dense>
                  <v-col md="12" class="mt-0 pt-0">
                    <div class="mt-3">
                      <div class="ul-icon-box mx-auto">
                        <div class="bg-group ul-animate fallingClouds" />
                        <v-img
                          height="100"
                          width="100"
                          class="mx-auto"
                          src="@/assets/images/svg/timeline.svg"
                        />
                      </div>
                      <div class="text-center">
                        <h4 class="font-weight-bold mb-0">Review your asset</h4>
                        <p class="text--lighten-1">
                          And schedule your pentest!
                        </p>
                      </div>
                      <v-divider class="my-4" />
                      <v-row dense>
                        <v-col cols="12">
                          <h5 class="mt-0 pt-0">Definition</h5>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 text-18 mr-2">Name :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p class="mb-1 text-18">
                            {{ assetName }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 text-18 mr-2">Type :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p class="mb-1 text-18">
                            {{ assetType }}
                          </p>
                        </v-col>
                      </v-row>
                      <!--<v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 text-18 mr-2">Technologies :</h6>
                        </v-col>

                        <v-col cols="9">
                          <p v-if="assetSelectedTechnologies.length" class="mb-1 text-18">
                            <v-chip
                              v-for="techno in assetSelectedTechnologies"
                              :key="techno"
                              pill
                              color="light"
                              class="mr-2"
                              small
                            >
                              {{ techno }}
                            </v-chip>
                          </p>
                          <p
                            v-else
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            <v-chip
                              pill
                              color="warning"
                              class="mr-2"
                              small
                            >
                              No Technologies
                            </v-chip>
                          </p>
                        </v-col>
                      </v-row>-->

                      <v-divider class="my-4" />
                      <v-row dense>
                        <v-col cols="12">
                          <h5 class="mt-0 pt-2">Targets</h5>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 text-18 mr-2">Description :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p v-if="assetDescription" class="mb-1 text-18" style="white-space: pre-line">
                            {{ assetDescription }}
                          </p>
                          <p
                            v-else
                            class="mb-1 text-18"
                            style="white-space: pre-line"
                          >
                            <v-chip
                              pill
                              color="warning"
                              class="mr-2"
                              small
                            >
                              No description
                            </v-chip>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" md="3">
                          <h6 class="my-1 text-18 mr-2">Targets :</h6>
                        </v-col>
                        <v-col cols="9">
                          <p class="mb-1 text-18" style="white-space: pre-line">
                            {{ assetTargets }}
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-card>

              <div class="text-right mr-4">
                <v-btn
                  color="primary"
                  outlined
                  class="mr-3"
                  @click="stepperStep = 2"
                >
                  Back
                </v-btn>

                <v-btn color="primary" @click="submitData">Submit</v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AssetService from '@/services/asset.service'
import ClientService from '@/services/client.service.js'
import snackbarPlugin from '@/plugins/snackbar'
import { assetTypes, assetTechnos } from '@/data/common'
import UserService from '@/services/user.service'
import AsyncSearchSelect from '@/components/base/AsyncSearchSelect.vue'

export default {
  components: { AsyncSearchSelect },
  props: {
    value: Boolean
  },
  data: () => ({
    stepperStep: 1,
    assetGroup: 'existing',
    assetTypes,
    assetTechnos,
    assetSelectedTechnologies: [],
    assetType: '',
    assetName: '',
    assetTargets: null,
    assetDescription: '',
    pentestTargets: null,
    isSuperUser: false,
    client: null,
    itemsClient: [],

    valid1: true,
    valid2: true
  }),
  computed: {
    assetSelection() {
      return this.assetGroup
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
    }
  },
  methods: {
    async fetchClientRefs(search) {
      ClientService.getClientRefs(1, 10, search).then((resp) => {
        this.itemsClient = resp.results
      })
    },
    nextStep(step) {
      if (this.$refs.form1.validate()) {
        this.stepperStep = step
      }
      if (!this.$refs.form1.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    },
    nextStepF2(step) {
      if (this.$refs.form2.validate()) {
        this.stepperStep = step
      }
      if (!this.$refs.form2.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    },
    closeModal() {
      this.resetFields()
      this.$emit('input', false)
      this.stepperStep = 1
    },
    formatTarget() {
      if (this.assetTargets === null || this.assetTargets <= 0) {
        this.assetTargets = null
      } else {
        // removed because inconsistent with instruction label
        // const regexTarget = this.assetTargets.replace(/(\r\n|\n|\r|\s+)/gm, '\n')
        // const arrTarget = regexTarget.split('\n')
        const arrTarget = this.assetTargets.split('\n')
        const newArrayTarget = []
        for (let i = 0; i < arrTarget.length; i++) {
          const res = arrTarget[i]
          if (res.length >= 0) {
            newArrayTarget.push(res)
          }
        }
        this.pentestTargets = newArrayTarget
      }
    },
    resetFields() {
      this.$refs.form1.reset()
      this.$refs.form2.reset()
    },
    submitData() {
      snackbarPlugin.showLoading('Creating ...')
      this.formatTarget()
      const asset = {
        name: this.assetName,
        type: this.assetType,
        technos: this.assetSelectedTechnologies,
        description: this.assetDescription,
        targets: this.pentestTargets,
        hasInternetAccess: true,
        icon: 'globe'
      }
      if (this.isSuperUser) {
        asset.clientId = this.client.id
      }
      AssetService.postAsset(asset)
      snackbarPlugin.showSuccess(`Asset ${this.assetName} Created!`)
      this.$swal({
        text: "You've just created an asset",
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok, got it!',
        customClass: {
          confirmButton: 'btn fw-bold btn-light-primary'
        }
      })
      this.resetFields()
      this.stepperStep = 1
      this.$emit('input', false)
      this.$store.dispatch('initializeData/refreshData', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-stepper__step__step {
  height: 36px !important;
  width: 36px;
  font-size: 1.3rem;
}

.v-stepper__header {
  box-shadow: none;
}
.removeBG {
  background-color: transparent !important;
}
</style>
