<template>
  <div>
    <base-dialog
      :show="showEditDialog"
      form-title="Edit Asset"
      icon="mdi-pencil"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <v-list-item-content class="text-left my-0">
              <div class="text-left primary--text text--lighten-2 font-weight-bold">NAME <span class="h6 primary--text text--lighten-2">*</span></div>
            </v-list-item-content>
            <v-text-field
              v-model="editedItem.name"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter your asset name"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <v-list-item-content class="text-left my-0" style="width: 100%">
              <div class="text-left primary--text text--lighten-2 font-weight-bold">
                TYPE <span class="h6 primary--text text--lighten-2">*</span>
              </div>
            </v-list-item-content>
            <v-select
              v-model="editedItem.type"
              placeholder="Selecte type"
              :items="assetTypes"
              :rules="[v => !!v || 'This field is required']"
              clearable
              outlined
              dense
            />
          </v-col>
          <!-- <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <v-list-item-content class="text-left my-0" style="width: 100%">
              <div class="text-left primary--text font-weight-bold">TECHNOLOGY <small class="font-italic font-weight-thin">(Optional)</small></div>
            </v-list-item-content>
            <v-combobox
              v-model="editedItem.technos"
              :items="assetTechnos"
              chips
              multiple
              outlined
              clearable
              dense
            />
          </v-col> -->
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              DESCRIPTION <small class="font-italic font-weight-thin">(Optional)</small>
            </div>
            <v-textarea
              v-model="editedItem.description"
              placeholder="Add a description"
              outlined
              clearable
              no-resize
              dense
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              TARGETS <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-textarea
              v-model="editedItem.targets"
              :rules="[v => !!v || 'This field is required']"
              outlined
              clearable
              placeholder="Add your targets, Ex: 127.0.0.1"
              no-resize
              dense
              @change="formatTarget"
            />
          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import AssetService from '@/services/asset.service'
import snackbarPlugin from '@/plugins/snackbar'
import { assetTypes, assetTechnos } from '@/data/common'

export default {
  components: {
    BaseDialog
  },
  props: {
    showEditDialog: {
      type: Boolean,
      required: true
    },
    editedItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      assetTypes,
      assetTechnos,
      valid: true
    }
  },
  methods: {
    close() {
      this.$emit('update:show-edit-dialog', false)
    },
    formatTarget() {
      if (this.editedItem.targets === null || this.editedItem.targets <= 0) {
        this.editedItem.targets = null
      } else {
        const regexTarget = this.editedItem.targets.replace(/(\r\n|\n|\r|\s+|[, ])/gm, '\n')
        const arrTarget = regexTarget.split('\n')
        const newArrayTarget = []
        for (let i = 0; i < arrTarget.length; i++) {
          const res = arrTarget[i]
          newArrayTarget.push(res)
        }
        this.editedItem.targets = newArrayTarget
      }
    },
    save() {
      snackbarPlugin.showLoading('Updating ...')
      if (this.$refs.form.validate()) {
        AssetService.putAsset(this.editedItem.id, this.editedItem).then(() => {
          snackbarPlugin.showSuccess(`Asset ${this.editedItem.name} updated!`)
          this.$emit('reload-items')
          this.close()
        })
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
