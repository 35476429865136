<template>
  <div class="px-1">
    <v-row class="mt-0 pt-0">
      <v-col cols="12" class="mt-0 pt-0">
        <asset-toolbar
          :filter-keyword.sync="filterSearch"
          :filter-type.sync="filterType"
          :filter-client.sync="filterClient"
        />

        <asset-table
          ref="assetTable"
          :filter-search.sync="filterSearchUpdate"
          :filters.sync="assetFilters"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>

import AssetToolbar from '../components/toolbar/AssetToolbar.vue'
import AssetTable from '../components/table/AssetTable.vue'
import UserService from '@/services/user.service'

export default {
  name: 'Assets',
  metaInfo: {
    title: 'Assets'
  },
  components: {
    AssetToolbar,
    AssetTable
  },
  data: () => ({
    filterSearch: null,
    filterType: null,
    filterClient: null,
    isAdmin: false,
    isSuperUser: false
  }),
  computed: {
    assetFilters() {
      return {
        type: this.filterType,
        clientID: this.filterClient ? this.filterClient.id : null
      }
    },
    filterSearchUpdate() {
      if (this.filterSearch !== null) {
        this.$refs.assetTable.searchAfterWait()
        return this.filterSearch
      }
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isAdmin = profile.role !== 'Client User'
      this.isSuperUser = profile.role === 'Superuser'
    }
    this.$store.dispatch('changeThemeLoadingState', false)
  }
}
</script>
<style lang="scss" scoped>
table.v-table tbody td,
table.v-table tbody th {
  min-height: 50px !important
}

.v-stepper,
.v-stepper__header {
  box-shadow: none !important
}
</style>
