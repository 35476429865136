import api from './api'
import { paginate } from '@/services/utils'

class AssetService {
  async postAsset(data) {
    const response = await api.post('/api/v1/assets/', data)
    return response.data
  }

  async getAssets(page, perPage, search, filters) {
    const requestUrl = paginate('api/v1/assets/', page, perPage, search, filters)
    const response = await api.get(requestUrl)
    return response.data
  }

  async getAssetRefs(page, perPage, search, filters) {
    const requestUrl = paginate('api/v1/assets/refs', page, perPage, search, filters)
    const response = await api.get(requestUrl)
    return response.data
  }

  async getByIdAsset(id) {
    const response = await api.get(`/api/v1/assets/${id}`)
    return response.data
  }

  async putAsset(id, data) {
    const response = await api.put(`/api/v1/assets/${id}`, data)
    return response.data
  }

  async deleteAsset(id) {
    const response = await api.delete(`/api/v1/assets/${id}`)
    return response.data
  }
}

export default new AssetService()
