<template>
  <div>
    <edit-asset-dialog
      :edited-item="editedItem"
      :show-edit-dialog.sync="showEditDialog"
      v-on:reload-items="fetchAssets"
    />

    <delete-asset-dialog
      :edited-item="editedItem"
      :show-delete-dialog.sync="showDeleteDialog"
      v-on:reload-items="fetchAssets"
    />

    <v-card flat>
      <v-data-table
        :headers="headers"
        :items="assets"
        :server-items-length="assetCount"
        :loading="isLoading"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: pageSizeOptions
        }"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar color="grey" class="my-5 d-none d-sm-flex">
            <v-icon medium class="my-2">
              mdi-package-variant
            </v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.name="{ item }">
          <h6 v-if="item.isDeleted" class="error--text ma-0">{{ item.name }}</h6>
          <h6 v-else class="ma-0 primary--text text--lighten-2 pointer" @click="editItem(item)">{{ item.name }}</h6>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <div>
            {{ displayDate(item) }}
          </div>
        </template>

        <template v-slot:item.client="{ item }">
          <div
            v-if="item.client"
            class=""
          >
            {{ item.client.name }}
          </div>
        </template>

        <template v-slot:item.type="{ item }">
          <v-chip
            color="primary lighten-2"
            outlined
          >
            <span v-if="item.type === 'mobile'">Mobile application</span>
            <span v-if="item.type === 'network'">Network</span>
            <span v-if="item.type === 'other'">Other</span>
            <span v-if="item.type === 'iot'">IOT Device</span>
            <span v-if="item.type === 'api'">Web API</span>
            <span v-if="item.type === 'app'">Web application</span>
          </v-chip>
        </template>

        <template v-slot:item.technos="{ item, index }">
          <span v-for="(tech, techIndex) in item.technos" :key="techIndex">
            <v-chip
              v-if="index <= 2"
              class="ma-2"
              color="primary"
              outlined
            >
              {{ tech }}
            </v-chip>
          </span>
          <v-chip v-if="item.technos.length > 3">+ {{ item.technos.length -3 }} others</v-chip>
        </template>

        <template v-slot:item.pentestCount="{ item }">
          <v-chip
            color="success"
            outlined
            :disabled="!item.lastProjectId"
            @click="fullViewItem(item.lastProjectId)"
          >
            {{ item.pentestCount }} Pentest
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <span class="d-none d-lg-flex">
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary lighten-2"
                  depressed
                  class=""
                  icon
                  large
                  :disabled="item.lastReportFile === null"
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadReport(item)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download Last Report</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isAdmin"
                  color="primary lighten-2"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isAdmin"
                  color="danger"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
            <v-spacer />
          </span>
          <v-menu
            bottom
            left
            class="d-md-flex d-lg-none"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="d-md-flex d-lg-none ml-14"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item
                link
                :class="item.lastReportFile === null ? 'gray--text' : 'primary--text text--lighten-2'"
                :disabled="item.lastReportFile === null"
                @click="downloadReport(item)"
              >
                <v-icon class="mr-2" :class="item.lastReportFile === null ? 'gray--text' : 'primary--text text--lighten-2'">mdi-download</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Last Report</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="isAdmin"
                link
                class="primary--text text--lighten-2"
                @click="editItem(item)"
              >
                <v-icon class="mr-2" color="primary lighten-2">mdi-pencil</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="isSuperUser"
                link
                class="error--text"
                @click="deleteItem(item)"
              >
                <v-icon class="mr-2" color="error">mdi-delete-outline</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DeleteAssetDialog from '../dialog/assets/DeleteAssetDialog.vue'
import EditAssetDialog from '@/components/dialog/assets/EditAssetDialog'
import FilesService from '@/services/files.service.js'
import snackbarPlugin from '@/plugins/snackbar'
import _ from 'lodash'
import AssetService from '@/services/asset.service'
import UserService from '@/services/user.service'

export default {
  components: {
    DeleteAssetDialog,
    EditAssetDialog
  },
  props: {
    filterSearch: {
      type: String || undefined,
      default: ''
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      showDeleteDialog: false,
      showEditDialog: false,
      editedItem: {},
      isAdmin: false,
      isSuperUser: false,
      search: this.$route.query.search || '',
      assets: [],
      assetCount: 0,
      pageSizeOptions: [5, 10, 25],
      isLoading: false,
      debounceSearch: null,
      options: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '5', 10) || 5,
        search: this.$route.query.search || '',
        type: null,
        clientID: null
      },
      previousCall: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '5', 10) || 5,
        search: this.$route.query.search || '',
        type: null,
        clientID: null
      },
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'icon',
          width: '20px',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Asset',
          value: 'name',
          class: 'text-left font-weight-medium subtitle-1'
        },

        // Client section [Only for SU]
        {
          text: '',
          value: '',
          class: ''
        },
        {
          text: 'Type',
          value: 'type',
          sortable: false,
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1'
        },
        // {
        //   text: 'Technos',
        //   value: 'technos',
        //   sortable: false,
        //   align: 'center',
        //   class: 'text-center font-weight-medium subtitle-1',
        // },
        {
          text: 'Pentests',
          value: 'pentestCount',
          sortable: false,
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Created on',
          value: 'createdAt',
          width: '160px',
          class: 'text-left font-weight-medium subtitle-1'
        },        
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '180px',
          class: 'text-center font-weight-medium subtitle-1'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchAssetsHandler()
      },
      deep: true
    },
    filters: {
      handler() {
        this.options.type = this.filters.type
        this.options.clientID = this.filters.clientID
      },
      deep: true
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isAdmin = profile.role !== 'Client User'
      if (profile.role === 'Superuser') {
        this.isSuperUser = true
        this.headers[2].text = 'Client'
        this.headers[2].value = 'client'
        this.headers[2].class = 'text-left font-weight-medium subtitle-1'
      } else {
        this.isSuperUser = false
      }
    }
    this.fetchAssets()
  },
  methods: {
    searchAfterWait() {
      this.search = this.filterSearch
      if (!this.debounceSearch) {
        this.debounceSearch = _.debounce(this.fetchAssetsHandler, 700)
      }
      this.debounceSearch()
    },
    fetchAssetsHandler() {
      const newCall = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.search,
        type: this.options.type,
        clientID: this.options.clientID
      }
      if (
        newCall.page === this.previousCall.page
        && newCall.itemsPerPage === this.previousCall.itemsPerPage
        && newCall.search === this.previousCall.search
        && newCall.type === this.previousCall.type
        && newCall.clientID === this.previousCall.clientID
      ) {
        return
      }
      if (
        (
          newCall.itemsPerPage !== this.previousCall.itemsPerPage
          || newCall.search !== this.previousCall.search
          || newCall.type !== this.previousCall.type
          || newCall.clientID !== this.previousCall.clientID
        ) && this.options.page !== 1
      ) {
        this.options.page = 1 // reset to page 1 will retrigger the call
        return
      }
      this.previousCall = newCall
      this.fetchAssets()
    },
    fetchAssets() {
      this.isLoading = true
      const { page, itemsPerPage, type, clientID } = this.options
      AssetService.getAssets(
        page, itemsPerPage, this.search, { type: type, clientID: clientID }
      ).then((assets) => {
        this.assets = assets.results
        this.assetCount = assets.total
      }).catch(() => {})
      this.isLoading = false
    },
    async downloadReport(item) {
      this.editedItem = Object.assign({}, item)
      await FilesService.getDownloadFileById(item.lastReportFile.id, item.lastReportFile.name, item.lastReportFile.type)
      this.$swal({
        text: 'Download has just started!',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok, got it!',
        customClass: {
          confirmButton: 'btn fw-bold btn-light-primary'
        }
      }).then(() => {
        this.dialog = false
      })
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showEditDialog = true
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showDeleteDialog = true
    },
    fullViewItem(id) {
      if (id !== null) {
        this.$router.push(`/pentest/detail/${id}`)
      } else {
        snackbarPlugin.showError('There is no pentest associated with this asset!')
      }
    },
    displayDate(item) {
      return `${new Date(item.createdAt.slice(0, 16) + '+0000').toLocaleDateString('fr-CA').replace(/\//g, '-')}`
    },
    displayTime(item) {
      return `${new Date(item.createdAt.slice(0, 19) + '+0000').toLocaleTimeString('fr-CA', { hour12: false })}`
    }
  }
}
</script>
